/* Register Modal css */
.homepage-modal .modal-header {
    display: flex;
    align-items: center;
    border-bottom: none !important;
}

.homepage-modal .modal-close-btn {
    border: none;
    background-color: transparent;
}


.homepage-modal .modal-footer {
    border-top: none !important;
}

.homepage-modal .modal-list {
    padding: 10px 20px;
}
.homepage-modal .modal-body {
    overflow-y: auto;
    z-index: 2;
}

.homepage-modal.rules .modal-body {
    overflow-y: auto;
    z-index: 2;
    height: 65vh;
}

.homepage-modal .modal-body h3 {
    font-size: 24px;
    font-weight: 500;
    color: #151852;
}

.homepage-modal .modal-body h3 span {
    font-size: 24px;
    font-weight: 500;
    color: #33CA8F;
}


.homepage-modal .modal-body .modal-list h5 {
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
    color: #585A82;
}

.homepage-modal .modal-body .submit-btn {
    background-color: #33ca8f;
    color: white;
    border-radius: 8px;
    padding-top: 19px;
    padding-bottom: 19px;
    padding-left: 57.44px;
    padding-right: 57.44px;
}

.homepage-modal .modal-body .modal-footer-part {
    text-align: center;
}

.homepage-modal .modal-body .modal-footer-part h4 {
    font-size: 22px;
    font-weight: 500;
    color: #151852;
}

.homepage-modal .modal-body .modal-footer-part p {
    color: #585A82;
}

.homepage-modal .modal-body .register-btn {
    padding: 10px 20px;
    border-radius: 5px;
    background: linear-gradient(180deg, #33CA8F 0%, #0BB8C8 100%);
    border: none;
    color: #fff;
    margin: 20px 0;
}



.homepage-modal .leaf-backgraund {
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
}

.homepage-modal .sec-leaf-backgraund {
    position: absolute;
    bottom: 0;
    left: 30px !important;
    opacity: 0.3;
}

.homepage-modal .custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}

.homepage-modal .custom-checkbox .custom-control-label:after {
    top: 12px;
}

.homepage-modal  .custom-checkbox .custom-control-label::before {
    border-radius: 3px;
    top: 12PX;
}

.custom-control-label::after {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%;
}

.homepage-modal  .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url(../assets/images/true-mark.svg);
}

.modal-close-btn img{
    width: 15px;
    height: 15px;
}

/* conteswt rule modal */

.homepage-modal .modal-body .list-Content h5 {
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #151852;
}


.rulesModal-close-btn {
    padding: 6px 20px;
    border: 2px solid #33CA8F;
    background-color: transparent;
    color: #33CA8F;
    border-radius: 5px;
}
.rulesModal-close-btn:hover{
    background-color: #33CA8F !important;
    color: white !important;
}

.homepage-modal ul {
    padding: 0;
    margin: 0;
}

.homepage-modal ul li {
    margin-left: 20px;
    list-style: none;
    color: #585A82;
    font-size: 14px;
    font-weight: 400;
}

.homepage-modal ul li::before {
    
    position: absolute;
    content: "";
    background-image: url(../assets/images/green-bullet.svg);
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    color: rgb(80, 80, 80);
    font-size: 14px;
    font-weight: 450;
    line-height: 17.71px;
    letter-spacing: 0.02em;
    left: 0;
}